import React, {
  Component,
  Fragment } from 'react';

import NavBar from '../navigation/navbar';

import grade from '../../static/images/noun_grade.png';
import prevention from '../../static/images/noun_prevention.png';
import segmentation from '../../static/images/noun_segmentation.png';

class TechInovation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        segmentador: <Fragment><div className="container">
            <p>Esta herramienta nos permite realizar un análisis detallado de los negocios que forman parte de nuestra plataforma. ​</p>
            <p>El análisis incorpora diferentes criterios de evaluación para agrupar las PyMES en segmentos con características comunes, permitiendo desarrollar una estrategia de atención y promoción más precisa.</p>
            <ol>
              <li>Para utilizar la herramienta es necesario seleccionar un tipo de segmentación y los atributos del análisis</li>
              <li>Al dar click en “generar segmentos”, el sistema realizará un análisis y mostrará una gráfica de segmentación</li>
              <li>En la parte inferior de la pantalla se podrá observar características comunes del segmento dando click en cada uno</li>
            </ol>
          </div></Fragment>,
        calificador: <Fragment>
          <p>Esta herramienta nos permite tener acceso al listado de PyMES e indicadores con impacto directo para asegurar una experiencia positiva del cliente: </p>
          <ul>
            <li>calificación del vendedor otorgada por compradores</li>
            <li>costo-beneficio del producto​</li>
            <li>% de devoluciones y retrasos sobre ventas totales​</li>
            <li>cancelaciones de compra en el periodo, etc.​</li>
          </ul>
          <p>Dentro del listado se otorgará una evaluación general de acuerdo a los criterios internos establecidos por el administrador para seleccionar los comercios que recibirán el marcador de “certificación” como vendedor confiable​</p>
          <ol>
            <li>Para utilizar la herramienta es necesario seleccionar una o varias categorías de negocio</li>
            <li>Se desplegará un listado de PyMES que mostrará sus principales indicadores </li>
            <li>En la última columna es posible activar o desactivar el marcador de “certificación”</li>
          </ol>
        </Fragment>,
        integracion: <Fragment>
          <p>Esta herramienta nos permite tener acceso al listado de PyMES e indicadores básicos de devoluciones, impagos y bloqueos para poder analizar aquellos negocios con mayor potencial de ser incorporados a un sistema de prevención de fraude y de gestión de contracargos.</p>
          <p>El agregador permitirá entender el potencial individual y total para eficientar la inversión en el servicio a través de la consolidación de volúmenes de transacciones.</p>
          <ol>
            <li>Para utilizar la herramienta es necesario seleccionar una o varias categorías de negocio​</li>
            <li>Se desplegará un listado de PyMES que mostrará sus principales indicadores respecto a situaciones que provocan un experiencia negativa​</li>
            <li>La herramienta permitirá seleccionar los negocios a incorporar a Accertify</li>
          </ol>
        </Fragment>,
      },
      selectedElement: null
    }
  }

  handleSelectedElementClass = (element) => {
    if(element === this.state.selectedElement) {
      return "is-selected"
    }
  }

  selectElement = (element) => {
    this.setState({ selectedElement: element })
  }

  render() {
    return (
      <Fragment>
        <section className="hero is-white">
          <div className="hero-header">
            <NavBar />
          </div>
          <div className="hero-body">
            <div className="container content">
              <p className="title">Innovación Tecnológica</p>
              <p className="subtitle is-gray">Coloca el cursor sobre una opción para conocer más sobre la herramienta</p>
              <div className="box">
                <div className="columns">
                  <div className="column is-4">
                    <a href="http://segmentador-mlpymes.mystratis.com/" className={"button is-large is-fullwidth has-space bot is-orange " + this.handleSelectedElementClass("segmentador") } onMouseEnter={ () => this.selectElement("segmentador") }>
                      <span>
                        <img source={ grade } />
                      </span>
                      <span>Segmentador ML de PyMES</span>
                    </a>
                    <a className={ "button is-large has-space is-fullwidth has-text-right is-orange " + this.handleSelectedElementClass("calificador") } onMouseEnter={ () => this.selectElement("calificador") }>
                      <span>
                        <img source={ prevention } />
                      </span>
                      <p>Calificador de PyMES</p>
                    </a>
                    <a className={ "button is-large has-space is-fullwidth has-text-right is-orange " + this.handleSelectedElementClass("integracion") } onMouseEnter={ () => this.selectElement("integracion") }>
                      <span>
                        <img source={ segmentation } />
                      </span>
                      <p>Integración Accertify</p>
                    </a>
                  </div>
                  <div className="column">
                    <p className="subtitle">Descripción</p>
                    { this.state.info[this.state.selectedElement] }
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
export default TechInovation
