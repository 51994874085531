import React from 'react';
import {
  Router,
  Route} from 'react-router-dom'
import history from './components/utils/history';
import './App.scss';

import GraphMaker from './components/pages/graphMaker';
import TechInovation from './components/pages/techInovation';

function App() {
  return (
    <div className="App">
      <Router history={ history }>
        <Route exact path="/">
          <TechInovation />
        </Route>
        <Route exact path="/graph">
          <GraphMaker type={ "regular" } />
        </Route>
        <Route exact path="/graph-offset">
          <GraphMaker type={ "offset" } />
        </Route>
      </Router>
    </div>
  );
}

export default App;
