import React, {
  Component } from 'react';


import u15 from '../../static/images/u15.png';
import u14 from '../../static/images/u14.svg';
import u10 from '../../static/images/u10.svg';
import u5 from '../../static/images/u5.svg';

class NavBar extends Component {
  render() {
    return (
      <nav className="level content-center is-marginless is-paddingless">
        <div className="container is-header">
          <div className="columns is-vcentered">
            <div className="column">
              <nav className="level content-left">
                <img src={ u15 } className="logo" alt="" />
                <div className="nav-item">
                  <p className="control has-icons-left">
                    <input className="input" type="text" placeholder="You are looking for"></input>
                      <span className="icon is-small is-left is-orange">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                  </p>
                </div>
                <div className="nav-item">
                  <p className="control has-icons-left">
                    <input className="input" type="text" placeholder="Near..."></input>
                      <span className="icon is-small is-left has-text-dark">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                  </p>
                </div>
              </nav>
            </div>
            <div className="column is-centered">
              <nav className="level content-right">
                <div className="nav-item">
                  <button className="button is-danger">PUBLISH YOUR ADD</button>
                </div>
                <div className="nav-item">
                  <img src={ u14 } className="profile" alt="" />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
export default NavBar
