import React, {
  Component,
  Fragment } from 'react';

import Graph from '../graphMaker/graph';
import NavBar from '../navigation/navbar';


class GraphMaker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      graph: <Graph type={ this.props.type }/>
    }
  }

  render() {
    return (
      <Fragment>
        <section className="hero is-light is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-7 is-9-desktop">
                  <div className="box">
                    <h1 className="subtitle">Gráfica</h1>
                    { this.state.graph }
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
export default GraphMaker
